@import "../../_globalColor";

.main {
  width: 90%;
  padding: 20px 10px;
  
}
.greet-main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 1rem;
}


.subTitle {
  color: $subTitle !important;
}

.greeting-main {
  display: flex;
  align-items: center;
  
}

.greeting-main > * {
  
  margin-bottom: 5px;
}
.button-greeting-div {
  display: flex;
  margin-top: 5px;
}

.greeting-text {
  font-size: 70px;
  line-height: 1.1;
  flex: 2;
  color: $textColor !important;
}

.greeting-text-p {
  font-size: 30px;
  line-height: 40px;
}

.greeting-image-div{  
  display: flex;
  justify-content: center;
  margin: 30px;
  
}

.greeting-image-div > img {
  flex: 1;
  max-width: 100%;
  height: auto;
  box-shadow: 0px 10px 30px 10px $darkBoxShadow;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.25s ease;
  border-radius: 10px; /* Adjust the radius as needed */
  border: 2px solid $buttonHover; /* You can change the border width and color */
  
  
}

.wave-emoji {
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-name: nod;
  display: inline-block;
  transform-origin: 70% 70%;
}


.nodding-image {
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-name: nod;
  display: inline-block;
  width: 7%;
  content: url("../../../public/android-chrome-192x192.png");
  
  transform-origin: 70% 70%;
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-10deg);
  }
  20% {
    transform: rotate(12deg);
  }
  30% {
    transform: rotate(-10deg);
  }
  40% {
    transform: rotate(9deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes nod {
  0%, 100% {
    transform: scaleY(1);
  }
  20% {
    transform: scaleY(0.8);
  }
  40% {
    transform: scaleY(1.0);
  }
  60% {
    transform: scaleY(0.6);
  }
  80% {
    transform: scaleY(1.0);
  }
}



/* Media Query */
@media (max-width: 1380px) {
  .greeting-text {
    font-size: 50px;
  }
  .greeting-text-p {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .button-greeting-div {
    justify-content: space-around;
  }
  .greeting-text {
    font-size: 30px;
    text-align: center;
  }
  .greeting-text-p {
    font-size: 16px;
    line-height: normal;
    text-align: center;
  }
  .greeting-main {
    display: block;
  }
  .greeting-image-div {
    width: auto;
  }
}

@media (max-width: 320px) {
  .main {
    width: auto;
  }
}
